import { Button, Table } from "react-bootstrap";
import { Excursion } from "../../models/Excursion";
import { compareDesc } from "date-fns";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { formatToUTC } from "../../utils/date";
import * as Backend from "../../models/Backend";
import { toast } from "react-toastify";
import { useUser } from "../../hooks/useUser";
import env from "../../env";
import "./ExcursionList.module.scss";

interface ExcursionListProps {
	excursions: Excursion[];
	onDeleted: (excursion: Excursion) => void | Promise<void>;
}

const ExcursionList = ({ excursions, onDeleted }: ExcursionListProps): JSX.Element => {
	const { user } = useUser();

	const sortedExcursions = [...excursions].sort((a, b) => compareDesc(a.dateTime ?? 0, b.dateTime ?? 0));

	return (
		<Table responsive striped hover>
			<thead>
				<tr>
					<th>Navn</th>
					<th>Skole</th>
					<th>Nummer</th>
					<th>Dato</th>
					<th>Filer</th>
					<th>{/* Empty */}</th>
				</tr>
			</thead>
			<tbody>
				{sortedExcursions.map(excursion => (
					<tr key={excursion.id} className={"mb-3"}>
						<td align="left">
							<Link to={generatePath("/excursion/:id", { id: excursion.id })}>{excursion.name}</Link>
						</td>
						<td align="left">{excursion.school}</td>
						<td align="left">{excursion.number === 0 ? "-" : excursion.number}</td>
						<td align="left">{excursion.dateTime === null ? null : formatToUTC(excursion.dateTime)}</td>
						<td>
							<ul className="list-unstyled">
								<li>
									<a
										href={`${env.BACKEND_BASE_PATH}/csv/excursion/${excursion.id}`}
										target="_blank"
										rel="noopener noreferer"
									>
										Ekskursjon
									</a>
								</li>
								<li>
									<a
										href={`${env.BACKEND_BASE_PATH}/csv/stations?excursionId=${excursion.id}`}
										target="_blank"
										rel="noopener noreferer"
									>
										Lokaliteter
									</a>
								</li>
								<li>
									<a
										href={`${env.BACKEND_BASE_PATH}/csv/observations?excursionId=${excursion.id}`}
										target="_blank"
										rel="noopener noreferer"
									>
										Observasjoner
									</a>
								</li>
							</ul>
						</td>
						<td align="center">
							{user?.type === "dfh-user" && user.userId === excursion.owner && (
								<Button
									className={"delete-button"}
									variant={"outline-danger"}
									onClick={() => {
										void (async () => {
											if (!confirm("Er du sikker på at du vil slette denne ekskursjonen?")) {
												return;
											}

											try {
												await Backend.deleteExcursion(excursion.id);
												void onDeleted(excursion);
											} catch (err) {
												console.error(err);
												toast("Kunne ikke slette ekskursjonen", { type: "error" });
											}
										})();
									}}
								>
									<FontAwesomeIcon icon={faRemove} />
								</Button>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default ExcursionList;
export { ExcursionList };
