import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, FormFloating, FormLabel, FormControl, FormSelect } from "react-bootstrap";
import { useBaseId } from "../../hooks/useBaseId";
import { Area } from "../../models/Area";
import { Excursion } from "../../models/Excursion";
import * as yup from "yup";
import env from "../../env";
import { useEffect, useState } from "react";
import * as Backend from "../../models/Backend";
import { formatToUTC } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import styles from "./ExcursionForm.module.scss";

export type ExcursionFormData = Pick<Excursion, "name" | "school" | "number" | "areaId" | "dateTime" | "isVirtual">;

export interface ExcursionFormProps {
	/** The excursion to use to initially populate the form, if applicable */
	excursion?: Excursion;
	/** Handler for the form's submit event */
	onSubmit: (formData: ExcursionFormData) => void | Promise<void>;
	/** List of available areas */
	areas: Area[];
}

/** Form for creating an excursion */
const ExcursionForm = ({ excursion, onSubmit, areas }: ExcursionFormProps): JSX.Element => {
	const navigate = useNavigate();
	const fieldBaseId = useBaseId();

	// As the user can create more areas, we need to keep track of those
	const [otherAreas, setOtherAreas] = useState<Area[]>([]);
	const addArea = (newArea: Area) => setOtherAreas(otherAreas => [...otherAreas, newArea]);
	useEffect(() => {
		// Nothing to do if this is not editing an existing excursion
		if (excursion === undefined) {
			return;
		}

		// Nothing to do if the excursion does not have a custom area
		const hasArea = areas.find(({ id }) => excursion.areaId === id);
		if (hasArea) {
			return;
		}

		// TODO Properly call backend
		void (async () => {
			const area = await Backend.getArea(excursion.areaId);
			addArea(area);
		})();
	}, [areas, excursion]);

	const allAreas = [...areas, ...otherAreas];

	const otherValue = "other";

	// Putting label strings here to have them in only one place
	const nameLabel = "Ekskursjonsnavn";
	const schoolLabel = "Skole";
	const numberLabel = "Nummer";
	const areaLabel = "Område";
	const otherAreaLabel = "Spesifiser område";
	const dateLabel = "Dato";

	// Make sure the test snapshot doesn't change every day
	const currentDate = env.NODE_ENV === "test" ? new Date("2022-05-12") : new Date();

	const initialValues =
		excursion === undefined
			? {
					name: "",
					school: "",
					number: "",
					areaId: otherValue,
					areaOther: "",
					dateTime: formatToUTC(currentDate)
			  }
			: {
					name: excursion.name,
					school: excursion.school ?? "",
					number: String(excursion.number ?? ""),
					areaId: excursion.areaId,
					areaOther: "",
					dateTime: excursion.dateTime !== null ? formatToUTC(excursion.dateTime) : ""
			  };

	const formDataSchema = yup.object({
		name: yup.string().required(),
		school: yup.string(),
		number: yup.number(),
		areaId: yup.string().required(),
		areaOther: yup.string().when("areaId", {
			is: otherValue,
			then: yup.string().required(),
			otherwise: yup.string()
		}),
		dateTime: yup.date().required()
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={formDataSchema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					// Figure out area stuff
					let areaId = values.areaId;
					if (values.areaId === otherValue) {
						// Create a new area
						const newArea = await Backend.createArea(values.areaOther);
						areaId = newArea.id;
					}

					const parsedData: ExcursionFormData = {
						areaId,
						name: values.name,
						school: values.school,
						number: Number(values.number),
						dateTime: new Date(values.dateTime),
						// This one was confusing, terribly named, and a bandaid solution for lack of a proper
						// testing/staging environment. It's easier to just always set it to false than to fix it all
						// over the place.
						// TODO Remove it from everywhere in frontend and backend if proper funding is given to the
						// project
						isVirtual: excursion?.isVirtual ?? false
					};

					await onSubmit(parsedData);
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting, values }) => (
				<Form>
					{/* Excursion name */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-name`}
							type="text"
							name="name"
							placeholder={nameLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-name`}>{nameLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="name" />
					</FormFloating>

					{/* School */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-school`}
							type="text"
							name="school"
							placeholder={schoolLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-school`}>{schoolLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="school" />
					</FormFloating>

					{/* Number */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-number`}
							type="number"
							name="number"
							placeholder={numberLabel}
							min="1"
						/>
						<FormLabel htmlFor={`${fieldBaseId}-number`}>{numberLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="number" />
					</FormFloating>

					{/* Area */}
					<FormFloating className="mb-3">
						<Field
							as={FormSelect}
							id={`${fieldBaseId}-areaId`}
							name="areaId"
							placeholder={areaLabel}
							required
							className={styles["hidden"]}
						>
							<option value="">-- Velg --</option>
							{allAreas.map(area => (
								<option key={area.name} value={area.id}>
									{area.name}
								</option>
							))}
							<option value={otherValue}>Annet, spesifiser</option>
						</Field>
						<FormLabel htmlFor={`${fieldBaseId}-areaId`}>{areaLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="areaId" />
						{values.areaId === otherValue ? (
							<FormFloating className="mb-3">
								<Field
									as={FormControl}
									id={`${fieldBaseId}-areaOther`}
									placeholder={otherAreaLabel}
									type="text"
									name="areaOther"
									required
								/>
								<FormLabel htmlFor={`${fieldBaseId}-areaOther`}>{otherAreaLabel}</FormLabel>
								<ErrorMessage component={"p"} className="text-danger" name="areaOther" />
							</FormFloating>
						) : null}
						<FormFloating className="mb-3"></FormFloating>
					</FormFloating>

					{/* DateTime */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-dateTime`}
							type="date"
							name="dateTime"
							placeholder={nameLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-dateTime`}>{dateLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="dateTime" />
					</FormFloating>

					<div className="d-flex justify-content-between">
						{/* Submit */}
						<Button variant="success" type="submit" disabled={isSubmitting}>
							{excursion !== undefined ? "Rediger" : "Opprett"}
						</Button>
						{/* Cancel */}
						<Button variant="danger" type="button" onClick={() => navigate(-1)}>
							Avbryt
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ExcursionForm;
export { ExcursionForm };
