import Loading from "../application/Loading";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import * as Backend from "../../models/Backend";
import { useEffect, useState } from "react";
import { BottomType } from "../../models/BottomType";
import { toast } from "react-toastify";
import { Excursion } from "../../models/Excursion";
import useBottomTypes from "../../hooks/useBottomTypes";
import useInstruments from "../../hooks/useInstruments";
import StationForm from "../station/StationForm";
import { StandardLayout } from "../layouts/StandardLayout";
import { Instrument } from "../../models/Instrument";

interface RawCreateStationProps {
	bottomTypes: BottomType[];
	instruments: Instrument[];
	excursionId: Excursion["id"];
}

const RawCreateStation = ({ bottomTypes, instruments, excursionId }: RawCreateStationProps): JSX.Element | null => {
	const navigate = useNavigate();

	// Keep track of errors when submitting
	const [submitError, setSubmitError] = useState<string | null>(null);
	useEffect(() => {
		if (submitError === null) {
			return;
		}
		// Reset the submit error after 10 seconds
		const timeout = setTimeout(() => setSubmitError(null), 10000);

		return () => void clearTimeout(timeout);
	}, [submitError, setSubmitError]);

	return (
		<StandardLayout header="Opprett lokalitet">
			<StationForm
				bottomTypes={bottomTypes}
				instruments={instruments}
				onSubmit={async data => {
					const station = {
						...data,
						excursionId
					};

					// Send it to backend
					try {
						const { id: stationId } = await Backend.createStation(station);
						// Navigate to the newly created station
						navigate(generatePath("/viewStation/:stationId", { stationId }));
					} catch (err) {
						console.error(err);
						toast("Noe gikk galt", { type: "error" });
					}
				}}
			/>
		</StandardLayout>
	);
};

const CreateStation = (): JSX.Element | null => {
	const { excursionId } = useParams<"excursionId">();
	const bottomTypes = useBottomTypes();
	const instruments = useInstruments();

	if (excursionId === undefined) {
		throw new Error("Excursion ID not defined");
	}

	return bottomTypes === null || instruments === null ? (
		<Loading />
	) : (
		<RawCreateStation excursionId={excursionId} bottomTypes={bottomTypes} instruments={instruments} />
	);
};

export default CreateStation;
export { CreateStation, RawCreateStation };
