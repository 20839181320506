/* eslint-disable @typescript-eslint/await-thenable */
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, FormFloating, FormLabel, FormControl, FormSelect, Col, Row } from "react-bootstrap";
import * as yup from "yup";
import env from "../../env";
import { useBaseId } from "../../hooks/useBaseId";
import { BottomType } from "../../models/BottomType";
import { Station } from "../../models/Station";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Instrument } from "../../models/Instrument";
import useGeolocation from "../../hooks/useGeolocation";
import Collapsible from "../collapsible/Collapsible";
import handleTimeConversion from "../../hooks/useTimeStringConverter";

interface StationFormData {
	name: string;
	number: number;
	lat: number | null;
	lon: number | null;
	bottomTypeId: string | null;
	instrumentId: string | null;
	temperature: number;
	bottomTemperature: number | null;
	salinity: number | null;
	depth: number | null;
	visibility: number | null;
	dateTime: Date;
}

export interface StationFormProps {
	/** The station to use to initially populate the form, if applicable */
	station?: Station;
	/** Handler for the form's submit event */
	onSubmit: (values: StationFormData) => void | Promise<void>;
	/** List of available bottom types */
	bottomTypes: BottomType[];
	/** List of available instruments */
	instruments: Instrument[];
}

/** Form for creating a station */
const StationForm = ({ station, onSubmit, bottomTypes, instruments }: StationFormProps): JSX.Element => {
	const fieldBaseId = useBaseId();
	const navigate = useNavigate();

	const stationFormSchema = yup.object({
		name: yup.string().required(),
		number: yup.string().required(),
		lat: yup.number().min(-90).max(90),
		lon: yup.number().min(-180).max(180),
		temperature: yup.number().required(),
		bottomTemperature: yup.number(),
		salinity: yup.number(),
		depth: yup.number(),
		bottomTypeId: yup.string().oneOf(bottomTypes.map(({ id }) => id)),
		instrumentId: yup.string().oneOf(instruments.map(({ id }) => id)),
		visibility: yup.number(),
		dateTime: yup.date().required()
	});

	// Get the user's position
	const { pos, error: posError, update: updatePos } = useGeolocation(null);

	// Putting label strings here to have them in only one place
	const nameLabel = "Lokalitetsnavn";
	const numberLabel = "Klokkeslett på lokaliteten";
	const tempLabel = "Temperatur overflate (1. meter under overflaten)";
	const tempBottomLabel = "Temperatur bunn";
	const salinityLabel = "Saltholdighet ‰";
	const bottomTypeLabel = "Bunntype";
	const instrumentLabel = "Ble det brukt noe instrument for å gjøre målingene på lokaliteten?";
	const depthLabel = "Dyp på lokasjon";
	const visibilityLabel = "Synlighet målt ved sekkiskive";
	const dateLabel = "Dato";
	const lonLabel = "Lengdegrad";
	const latLabel = "Breddegrad";

	// Make sure the test snapshot doesn't change every day
	const currentDate = env.NODE_ENV === "test" ? new Date("2022-05-12") : new Date();

	const initialValues =
		station === undefined
			? {
					name: "",
					number: "",
					lat: "",
					lon: "",
					temperature: "",
					bottomTemperature: "",
					salinity: "",
					depth: "",
					bottomTypeId: "",
					instrumentId: "",
					visibility: "",
					dateTime: format(currentDate, "yyyy-MM-dd")
			  }
			: {
					name: station.name ?? "",
					number: handleTimeConversion(station.number) ?? "",
					lat: String(station.lat ?? ""),
					lon: String(station.lon ?? ""),
					temperature: String(station.temperature ?? ""),
					bottomTemperature: String(station.bottomTemperature ?? ""),
					salinity: String(station.salinity ?? ""),
					depth: String(station.depth ?? ""),
					bottomTypeId: station.bottomTypeId ?? "",
					instrumentId: station.instrumentId ?? "",
					visibility: String(station.visibility ?? ""),
					dateTime:
						station.dateTime !== null
							? format(station.dateTime, "yyyy-MM-dd")
							: format(currentDate, "yyyy-MM-dd")
			  };

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={stationFormSchema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				try {
					const timestring = values.number;
					const time = timestring.replace(":", "");
					const parsedData: StationFormData = {
						dateTime: new Date(values.dateTime),
						name: values.name,
						number: Number(time),
						lat: values.lat === "" ? null : Number(values.lat),
						lon: values.lon === "" ? null : Number(values.lon),
						bottomTypeId: values.bottomTypeId === "" ? null : values.bottomTypeId,
						instrumentId: values.instrumentId === "" ? null : values.instrumentId,
						temperature: Number(values.temperature),
						bottomTemperature: values.bottomTemperature === "" ? null : Number(values.bottomTemperature),
						salinity: values.salinity === "" ? null : Number(values.salinity),
						depth: values.depth === "" ? null : Number(values.depth),
						visibility: values.visibility === "" ? null : Number(values.visibility)
					};

					await onSubmit(parsedData);
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting, values, setFieldValue, setFieldTouched }) => (
				<Form>
					<Collapsible>
						<p>
							I lokalitetsskjemaet må du fylle ut de felter som er merket med
							{" " /* NB! Non-breaking space */}
							<span className="text-danger">*</span>
						</p>
						<p>
							Lokalitetsnavnet og nummer kan du velge selv, bruk gjerne et stedsnavn i nærheten. Du bør
							også nummerere lokalitetene i stigende rekkefølge. Posisjonen er obligatorisk, og dersom det
							ikke virker må du sjekke på enheten din at stedstjenester er aktivert og tillatt for dette
							systemet. Dersom du skal gjøre observasjoner av biologi eller søppel, gjør du det etter du
							har fylt ut lokalitetsskjemaet.
						</p>
					</Collapsible>
					{/* Station name */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-name`}
							as={FormControl}
							type="text"
							name="name"
							placeholder={nameLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-name`}>{nameLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="name" />
					</FormFloating>
					{/* Number */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-number`}
							name="number"
							type="time"
							pattern="[0-9]{2}.[0-9]{2}"
							minLength={5}
							maxLength={5}
							as={FormControl}
							placeholder={numberLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-number`}>{numberLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="number" />
					</FormFloating>
					{/* Position */}
					<Row className="mb-3">
						{station === undefined ? (
							<>
								<Col>
									{posError !== null ? <p className="text-danger">{posError}</p> : null}
									<Button
										variant="primary"
										type="button"
										// eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/require-await
										onClick={async () => {
											await updatePos();
											if (pos !== null) {
												setFieldValue("lat", pos.lat);
												setFieldValue("lon", pos.lon);
												setFieldTouched("lat", true);
												setFieldTouched("lon", true);
											}
										}}
									>
										{posError === null ? "Oppdater" : "Prøv igjen"}
									</Button>
								</Col>
							</>
						) : null}
						{values.lat !== "" && values.lon !== "" && values.lat !== null && values.lon !== null ? (
							<>
								<Col>
									<dl>
										<dt>Breddegrad</dt>
										<dd>{Number(values.lat)}</dd>
									</dl>
								</Col>
								<Col>
									<dl>
										<dt>Lengdegrad</dt>
										<dd>{Number(values.lon)}</dd>
									</dl>
								</Col>
								<Col>
									<dl>
										<dt>Google Maps</dt>
										<dd>
											<a
												href={`https://www.google.com/maps/@${values.lat},${values.lon},14z`}
												target="_blank"
												rel="noopener noreferer"
											>
												Se på kart
											</a>
										</dd>
									</dl>
								</Col>
							</>
						) : null}
					</Row>
					{/* Latitude */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-lat`}
							as={FormControl}
							type="number"
							name="lat"
							placeholder={latLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-lat`}>{latLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="lat" />
					</FormFloating>
					{/* Longitude */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-lon`}
							as={FormControl}
							type="number"
							name="lon"
							placeholder={lonLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-lon`}>{lonLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="lon" />
					</FormFloating>
					{/* Temperature */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-temperature`}
							as={FormControl}
							type="number"
							name="temperature"
							placeholder={tempLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-temperature`}>{tempLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="temperature" />
					</FormFloating>
					{/* Temperature Bottom*/}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-bottomTemperature`}
							as={FormControl}
							type="number"
							name="bottomTemperature"
							placeholder={tempBottomLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-bottomTemperature`}>{tempBottomLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="bottomTemperature" />
					</FormFloating>
					{/* Salinity */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-salinity`}
							as={FormControl}
							type="number"
							name="salinity"
							placeholder={salinityLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-salinity`}>{salinityLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="salinity" />
					</FormFloating>
					{/* Depth */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-depth`}
							as={FormControl}
							type="number"
							name="depth"
							placeholder={depthLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-depth`}>{depthLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="depth" />
					</FormFloating>
					{/* BottomType */}
					<FormFloating className="mb-3">
						<Field
							as={FormSelect}
							id={`${fieldBaseId}-bottomTypeId`}
							name="bottomTypeId"
							placeholder={bottomTypeLabel}
						>
							<option value="">-- Velg --</option>
							{bottomTypes.map(bottomType => (
								<option key={bottomType.name} value={bottomType.id}>
									{bottomType.name}
								</option>
							))}
						</Field>
						<FormLabel htmlFor={`${fieldBaseId}-bottomTypeId`}>{bottomTypeLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="bottomTypeId" />
					</FormFloating>
					{/* Instrument */}
					<FormFloating className="mb-3">
						<Field
							as={FormSelect}
							id={`${fieldBaseId}-instrumentId`}
							name="instrumentId"
							placeholder={instrumentLabel}
						>
							<option value="">-- Velg --</option>
							{instruments.map(instrument => (
								<option key={instrument.name} value={instrument.id}>
									{instrument.name}
								</option>
							))}
						</Field>
						<FormLabel htmlFor={`${fieldBaseId}-bottomTypeId`}>{instrumentLabel}</FormLabel>{" "}
						<ErrorMessage component={"p"} className="text-danger" name="bottomTypeId" />
					</FormFloating>
					{/* Visibility */}
					<FormFloating className="mb-3">
						<Field
							id={`${fieldBaseId}-visibility`}
							as={FormControl}
							type="text"
							name="visibility"
							placeholder={visibilityLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-visibility`}>{visibilityLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="visibility" />
					</FormFloating>
					{/* DateTime */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-dateTime`}
							type="date"
							name="dateTime"
							placeholder={nameLabel}
							required
						/>
						<FormLabel htmlFor={`${fieldBaseId}-dateTime`}>{dateLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="dateTime" />
					</FormFloating>
					<div className="d-flex justify-content-between">
						{/* Submit */}
						<Button variant="success" type="submit" disabled={isSubmitting}>
							{station !== undefined ? "Rediger" : "Opprett"}
						</Button>
						{/* Cancel */}
						<Button variant="danger" type="button" onClick={() => navigate(-1)}>
							Avbryt
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default StationForm;
export { StationForm };
