const handleTimeConversion = (value: number) => {
	const time = value.toString();
	if (time.length === 3 && timeRegex.test(time)) {
		const stringtime = "0" + time[0] + ":" + time[1] + time[2];
		return stringtime;
	} else if (time.length === 4 && timeRegex.test(time)) {
		const stringtime = time[0] + time[1] + ":" + time[2] + time[3];
		return stringtime;
	} else return time;
};

const timeRegex = /^(?:[01]?\d|2[0-3])[0-5]\d$/;

export default handleTimeConversion;
export { handleTimeConversion };
