import { Col, Container, Row } from "react-bootstrap";
import LoginForm from "../application/LoginForm";
import PinForm from "../excursion/PinForm";
import * as Backend from "../../models/Backend";
import { toast } from "react-toastify";
import { useNavigate, generatePath } from "react-router-dom";
import styles from "./Frontpage.module.scss";
import Collapsible from "../collapsible/Collapsible";
import env from "../../env";

const Frontpage = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Container fluid className={styles["login-container"]}>
			<Row>
				<Col className={`${styles["something"]} mx-auto mt-3 px-4 pt-3 pb-5`}>
					<div className={styles["pin"]}>
						<h1 className="text-uppercase">Ekskursjons-PIN</h1>
						<Collapsible>
							<p>
								For å registrere data på en ekskursjon så skal du her oppgi den PIN koden som
								toktlederen eller læreren din kan opplyse om etter at hen har opprettet ekskursjonen. Du
								vil da kunne opprette lokaliteter og observasjoner i denne ekskursjonen.
							</p>
						</Collapsible>
						<PinForm
							onSubmit={async pin => {
								try {
									const excursion = await Backend.loginWithPin(pin);
									navigate(generatePath("/excursion/:id", { id: excursion.id }));
								} catch (err) {
									if (err instanceof Error && err.message === "Invalid pin") {
										toast("Ugyldig PIN");
									} else {
										toast("En ukjent feil oppstod");
									}
								}
							}}
						/>
					</div>
					<div className={styles["login"]}>
						<h1 className="text-uppercase">Logg inn</h1>
						<p>For å opprette og administrere ekskursjoner</p>
						<LoginForm
							onSubmit={async credentials => {
								try {
									const success = await Backend.login(credentials);

									if (success) {
										navigate("/excursions");
									} else {
										toast("Ugyldig brukernavn og/eller passord", {
											type: "error"
										});
									}
								} catch (err) {
									toast(
										<p>
											Noe gikk galt. Kontakt{" "}
											<a href="mailto:dugnadhjelp@hi.no">dugnadhjelp@hi.no</a> om problemet
											vedvarer
										</p>,
										{
											type: "error"
										}
									);
								}
							}}
						/>
						<p className="text-center mt-4">
							<a
								href={`${env.ACCOUNTS_BASE_URL}/forgot-password`}
								target="_blank"
								rel="noreferrer noopener"
							>
								Glemt passord
							</a>
						</p>
						<p className="text-center">
							For å opprette bruker, gå til{" "}
							<a href={`${env.HAVFUNN_BASE_URL}`} target="_blank" rel="noopener noreferrer">
								Havfunn
							</a>{" "}
							og klikk "Opprett bruker". Følg instruksjonene, og kom så tilbake hit.
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Frontpage;
export { Frontpage };
