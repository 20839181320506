import Loading from "../application/Loading";
import { Button, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { ManualObservation } from "../../models/ManualObservation";
import { Station } from "../../models/Station";
import { Excursion } from "../../models/Excursion";
import * as dateFns from "date-fns";
import { useBottomType } from "../../hooks/useBottomTypes";
import { useInstrument } from "../../hooks/useInstruments";
import { useExcursion } from "../../hooks/useExcursion";
import useStation from "../../hooks/useStation";
import { useState } from "react";
import ObservationTypeSelectorModal from "../manualObservation/ObservationTypeSelectorModal";
import { useNavigate, generatePath } from "react-router";
import ObservationList from "./ObservationList";
import useSWR from "swr";
import * as Backend from "../../models/Backend";
import StandardLayout from "../layouts/StandardLayout";
import { useUser } from "../../hooks/useUser";
import handleTimeConversion from "../../hooks/useTimeStringConverter";

interface RawViewStationProps {
	manualObservations: ManualObservation[] | null;
	station: Station;
	excursion: Excursion;
	onManualObservationDeleted: (manualObservation: ManualObservation) => void | Promise<void>;
}

const RawViewStation = ({
	station,
	excursion,
	manualObservations,
	onManualObservationDeleted
}: RawViewStationProps): JSX.Element | null => {
	const navigate = useNavigate();
	const bottomType = useBottomType(station.bottomTypeId);
	const instrument = useInstrument(station.instrumentId);
	const { user } = useUser();

	const [addingObservation, setAddingObservation] = useState(false);

	let canCreateObservations = false;
	if (user?.type === "dfh-user" && user.userId === excursion.owner) {
		canCreateObservations = true;
	} else if (user?.type === "participant" && user.excursionId === excursion.id) {
		canCreateObservations = true;
	}

	return (
		<StandardLayout header={`Lokalitet - ${station.name}`}>
			<Link to={generatePath("/excursion/:id", { id: station.excursionId })} className="btn btn-primary">
				Tilbake til ekskursjon
			</Link>
			<Row as="dl">
				<Col xs={4} sm={3} md={2}>
					<dt>Navn</dt>
					<dd>{station.name}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Nummer / Klokkeslett</dt>
					<dd>{handleTimeConversion(station.number)}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Posisjon</dt>
					<dd>
						{station.lat === null || station.lon === null ? (
							"Ikke satt"
						) : (
							<a
								href={`https://www.google.com/maps/@${station.lat},${station.lon},14z`}
								target="_blank"
								rel="noopener noreferer"
							>
								{`${Math.abs(station.lat).toFixed(4)}${station.lat >= 0 ? "N" : "S"}, ${Math.abs(
									station.lon
								).toFixed(4)}${station.lon >= 0 ? "Ø" : "V"}`}
							</a>
						)}
					</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Bunntype</dt>
					<dd>{bottomType === null ? "Bunntype ikke satt" : bottomType.name}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Instrument</dt>
					<dd>{instrument === null ? "Instrument ikke satt" : instrument.name}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Temperatur overflate</dt>
					<dd>{station.temperature} °C</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Temperatur bunn</dt>
					<dd>{station.bottomTemperature === null ? "-" : station.bottomTemperature} °C</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Saltholdighet ‰</dt>
					<dd>{station.salinity}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Dyp på lokasjon</dt>
					<dd>{station.depth}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Dato</dt>
					<dd>
						{station.dateTime === null ? "Dato ikke satt" : dateFns.format(station.dateTime, "yyyy-MM-dd")}
					</dd>
				</Col>
			</Row>
			{canCreateObservations ? (
				<Row>
					<Col>
						<Button variant="primary" onClick={() => setAddingObservation(true)}>
							Ny observasjon
						</Button>
					</Col>
				</Row>
			) : null}
			<Row>
				<Col>
					{manualObservations === null || manualObservations.length === 0 ? (
						"Ingen opprettede observasjoner"
					) : (
						<ObservationList
							manualObservations={manualObservations}
							excursion={excursion}
							onDeleted={onManualObservationDeleted}
						/>
					)}
				</Col>
			</Row>
			<ObservationTypeSelectorModal
				show={addingObservation}
				onHide={() => setAddingObservation(false)}
				handleSelected={value => {
					navigate(
						generatePath(`/createManualObservation/:stationId/:type/:id`, {
							stationId: station.id,
							...value
						})
					);
				}}
			/>
			{canCreateObservations ? (
				<div className="d-flex justify-content-start mt-3">
					<Button
						variant="success"
						type="button"
						onClick={() => {
							// Navigate to edit station
							navigate(generatePath("/editStation/:id", { id: station.id }));
						}}
					>
						Rediger lokalitet
					</Button>
				</div>
			) : null}
		</StandardLayout>
	);
};

const ViewStation = (): JSX.Element | null => {
	const { stationId } = useParams<"stationId">() as { stationId: string };
	const { station } = useStation(stationId);
	const { excursion } = useExcursion(station?.excursionId ?? "nothing");

	const { data: manualObservations, mutate } = useSWR(stationId, Backend.getManualObservations, {
		refreshInterval: 10 * 1000
	});

	if (stationId === undefined) {
		throw new Error("Station ID not defined");
	}

	return station === null || excursion === null ? (
		<Loading />
	) : (
		<RawViewStation
			station={station}
			excursion={excursion}
			manualObservations={manualObservations ?? null}
			onManualObservationDeleted={manualObservation => {
				if (manualObservations === undefined) {
					return;
				}

				const newManObsArray = manualObservations.filter(({ id }) => id !== manualObservation.id);
				void mutate(newManObsArray);
			}}
		/>
	);
};

export { ViewStation, RawViewStation };
export default ViewStation;
