import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { BottomType } from "./BottomType";
import { Instrument } from "./Instrument";
import { Excursion } from "./Excursion";

export interface Station extends BaseType {
	excursionId: Excursion["id"];
	name: string;
	number: number;
	lat: number | null;
	lon: number | null;
	bottomTypeId: BottomType["id"] | null;
	instrumentId: Instrument["id"] | null;
	visibility: number | null;
	temperature: number;
	bottomTemperature: number | null;
	salinity: number | null;
	depth: number | null;
	dateTime: Date;
}

export const stationSchema = baseTypeSchema.shape({
	excursionId: yup.string().required(),
	name: yup.string().required(),
	number: yup.number().required(),
	lat: yup.number().nullable(),
	lon: yup.number().nullable(),
	bottomTypeId: yup.string().nullable(),
	instrumentId: yup.string().nullable(),
	visibility: yup.number().nullable(),
	temperature: yup.number().required(),
	bottomTemperature: yup.number().nullable(),
	salinity: yup.number().nullable(),
	depth: yup.number().nullable(),
	dateTime: yup.date().required()
});

stationSchema as yup.SchemaOf<Station>;
